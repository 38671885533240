<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
</style>
